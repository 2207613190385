<template>
  <v-dialog :value="value" @input="close" max-width="800px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h3>{{ $t("studies.acv.texts.add.title") }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-12">
            <!-- Lang -->
            <v-col cols="12">
              <SelectLanguage :value="form.lang" @input="onInputLanguage" />
            </v-col>

            <!-- Text -->
            <v-col cols="12">
              <v-textarea
                height="400"
                outlined
                :label="$t('studies.acv.texts.add.textarea')"
                v-model="form.text"
              />
            </v-col>

            <!-- Preview -->
            <v-col cols="12">
              <MarkdownViewer :value="form.text" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";
import formRules from "@/mixins/formRules";

export default {
  name: "ProductACVTextsAddLangDialog",

  mixins: [dialogMixin, formRules],

  props: {
    study: { type: Object, default: () => null },
  },

  components: {
    MarkdownViewer: () => import("@/components/Common/Inputs/MarkdownViewer"),
    SelectLanguage: () => import("@/components/Common/Inputs/SelectLanguages"),
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },

      defaultForm: {
        lang: "",
        text: "",
      },
    };
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.form);
      }
    },

    onInputLanguage(val) {
      this.form.lang = val;
    },
  },

  computed: {},
};
</script>

<style scoped></style>
